import { useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"

import { useQueryClient } from "@tanstack/react-query"

import { EmbeddedSettingsProfile } from "src/components/SettingsProfiles/EmbeddedSettingsProfile"
import { getCachedProfileFromList } from "src/data/homeProfiles/queries/homeProfilesCache"
import {
  useFetchProfile,
  usePatchProfile,
} from "src/data/homeProfiles/queries/HomeProfilesQueries"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { BackButton } from "src/ui/BackButton/BackButton"
import { Divider } from "src/ui/Divider/Divider"
import { Editable } from "src/ui/Editable/Editable"
import { MainView } from "src/ui/Layout/MainView"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function EditSettingsProfile() {
  const { t } = useTranslate()

  // Get profile id from url
  const params = useParams<{ profileId: string }>()
  const profileId = params.profileId

  const queryClient = useQueryClient()

  // Get profile by query
  const profileQuery = useFetchProfile({
    id: profileId,
    options: {
      initialData: getCachedProfileFromList(queryClient, profileId),
      onSuccess: (p) => {
        setProfile(p)
      },
    },
  })

  const [profile, setProfile] = useState<IProfileResponse | undefined>(
    profileQuery.data
  )

  const patchProfileReq = usePatchProfile()

  async function onProfileNameChange(
    oldProfile: IProfileResponse,
    name: string
  ) {
    setProfile({ ...oldProfile, name }) // eager update
    const newProfile = await patchProfileReq.mutateAsync({
      id: oldProfile.id,
      data: { name },
    })
    setProfile(newProfile)
  }

  if (!profile) {
    return null // TODO WEB-389: Error state
  }

  const nbrHomes = profile.number_of_homes
  return (
    <MainView
      title={
        <div>
          <MText variant="body">
            <BackButton
              to={Routes.MonitoringSettings.location().pathname}
              forceLinkNavigation={true}
            >
              {t(langKeys.back)}
            </BackButton>
          </MText>
          <PageTitle>
            <Editable
              onSave={(name: string) => onProfileNameChange(profile, name)}
              disabled={patchProfileReq.isLoading}
            >
              {profile.name}
            </Editable>
          </PageTitle>
        </div>
      }
      titleBarProps={{
        description: (
          <MText variant="body">
            {t(langKeys.profiles_edit_homes_affected, { count: nbrHomes })}
          </MText>
        ),
      }}
    >
      <StyledDivider />
      {!!profile && <EmbeddedSettingsProfile initialProfile={profile} />}
    </MainView>
  )
}

const StyledDivider = styled(Divider)`
  margin: ${spacing.XL} 0 ${spacing.XL2};
`
